// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.list-group {
  margin: 24px 0;

  .list-group-item {
    border-left: none;
    border-right: none;
    padding: 0;

    &.clickable:hover {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      z-index: 1;
    }
  }
}
