// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

.chart-list.list-group {
  margin-bottom: 0;

  .chart-list-item.list-group-item {
    height: 70px;
    padding: 16px 24px;
  }
}
