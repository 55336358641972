// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';
@import 'typography';

.table-detail {
  .column-layout-1 .left-panel {
    .alert {
      margin-top: $spacer-4;
      margin-bottom: 0;
    }

    .programmatic-title {
      color: $text-primary;
      font-weight: $font-weight-body-bold;
      margin: 30px 0 10px;
    }

    .programmatic-hr {
      border: 1px solid $gray15;
      margin: 10px 0 4px;
    }
  }

  .header-title-text {
    @extend %text-headline-w2;
  }

  .header-links {
    .avatar-label {
      &:focus,
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  .tab-title {
    display: flex;

    .loading-spinner {
      height: 20px;
      margin: 0 0 0 $spacer-1/2;
      width: 20px;
    }
  }

  .nav.nav-tabs {
    margin-top: $spacer-2;
    padding: 0 $spacer-2;
  }

  .tabs-component .nav.nav-tabs > li {
    margin: 0 $spacer-1;
  }

  .right-panel {
    position: relative;
  }

  .list-sorting-dropdown {
    right: $spacer-3;
    top: $spacer-2;
    position: absolute;
  }
}
