// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.btn.tag-button {
  background-color: $tag-bg;
  border: 0;
  border-radius: $tag-border-radius;
  color: $text-primary;
  margin: 0 8px 8px 0;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.compact {
    height: 30px;
    line-height: 14px;
  }

  &:hover,
  &:focus {
    background-color: $tag-bg-hover;
    color: $text-primary;
  }

  .tag-count {
    color: $text-secondary;
    margin-left: 8px;
  }
}
