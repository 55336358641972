// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.feedback-icon {
  height: 32px;
  outline: 0 !important;
  width: 32px;

  img {
    background-color: $white;
    margin: 4px;
  }

  &:hover,
  &:focus,
  &.is-open {
    border: 6px solid $white;
    border-radius: 50%;

    img {
      margin: -2px 0 0 -2px;

      &.icon.icon-help {
        background-color: white !important;
      }
    }
  }
}

.feedback-component {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 24px -2px rgba(0, 0, 0, 0.2);
  color: $text-primary;
  height: auto;
  min-height: 450px;
  padding: 32px;
  position: fixed;
  right: 76px;
  top: $nav-bar-height + 4px;
  width: 400px;
  z-index: 2;

  .feedback-header {
    display: flex;
    margin-bottom: 8px;

    .title {
      flex-grow: 1;
    }

    button {
      margin: auto 0;
    }
  }

  .btn-group {
    margin: 8px auto 16px;
  }

  .radio-set {
    display: flex;
    margin-top: 8px;
  }

  .radio-set-item {
    cursor: pointer;
  }

  .radio-set .radio-set-item:not(:first-child) {
    margin-left: 12px;
  }

  .radio-set-item label {
    text-align: center;
    width: 100%;
  }

  .nps-label {
    font-family: $font-family-header;
    font-weight: $font-weight-header-regular;
    margin-bottom: 15px;
    width: 65px;
  }

  .status-message {
    color: $text-secondary;
    font-family: $font-family-header;
    font-size: 20px;
    font-weight: $font-weight-header-regular;
    left: 50%;
    position: absolute;
    text-align: center;

    /* for centering when parent has automatic height */
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  input[type='radio'] {
    margin: 5px;
  }

  input[type='text'] {
    color: $text-secondary !important;
  }

  textarea {
    border: 1px solid $gray-lighter;
    border-radius: 5px;
    color: $text-secondary !important;
    padding: 10px;
    width: 100%;
  }
}
