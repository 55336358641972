// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';
@import 'typography';

$icon-status-color: #f2f2f2;
$icon-status-missed: $sunset60;
$icon-status-hit: #00824c;
$icon-size: 14px;

.failure-icon,
.success-icon {
  width: $icon-size;
  height: $icon-size;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
}

.failure,
.success {
  display: inline-block;
  margin-left: $spacer-1;
}

.failure-icon {
  background-color: $icon-status-missed;

  .exclamation-top {
    width: 2px;
    height: 4px;
    background-color: $icon-status-color;
    margin: 4px 6px 3px;
  }

  .exclamation-bottom {
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background-color: $icon-status-color;
    margin: -2px 6px;
  }
}

.success-icon {
  background-color: $icon-status-hit;

  .icon-check {
    background-color: $icon-status-color;
    width: 10px;
    height: 10px;
    margin-top: -6px;
    margin-left: 2px;
    min-width: 0;
  }
}

.status-text {
  @extend %text-body-w2;

  display: inline-block;
  margin-left: $spacer-1;
  vertical-align: middle;
}
