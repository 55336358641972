// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.overlay-trigger {
  display: inline-block;
}

.modal-dialog {
  width: 90%;

  .modal-content {
    min-height: 90%;

    .modal-title {
      font-family: $font-family-header;
      font-size: 20px;
      font-weight: $font-weight-header-bold;
    }

    .modal-body {
      margin: 15px;
      overflow-y: scroll;
      padding: 0;
      text-align: center;
    }
  }
}
