// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.loading-spinner {
  display: block;
  height: 100px;
  margin: auto;
  margin-top: 20%;
  width: 100px;
}
